import Employee from 'types/employee'
import { PositionsEnum } from 'types/positions'
import { RolesEnum } from 'types/roles'
import { NoSurvey, SurveyState } from 'types/survey'
import WorkCheckin from 'types/workCheckin'

import * as types from '../constants'

export type UserState = {
	loading: boolean
	error: boolean
	employee: Employee
	isAuthenticated: boolean
	todayEntered: boolean
	version: string
	lastCheckin: WorkCheckin | null
	workWithSystem: boolean
	eventsDisabled: boolean
	survey: SurveyState | NoSurvey
}

const initialState: UserState = {
	loading: false,
	error: false,
	employee: {
		_id: '',
		is_archived: false,
		username: '',
		first_name: '',
		last_name: '',
		birthday: null,
		hiring_date: new Date(),
		user_roles: [RolesEnum.base],
		position: PositionsEnum.other,
		end_test_period_date: new Date()
	},
	isAuthenticated: false,
	todayEntered: false,
	version: '',
	lastCheckin: null,
	workWithSystem: false,
	eventsDisabled: false,
	survey: {
		needed: false
	}
}

export default function reducer(state = initialState, actions) {
	switch (actions.type) {
		case types.LOGIN_REQUEST:
		case types.FETCH_USER_REQUEST:
		case types.ADD_WORK_CHECKIN_REQUEST:
		case types.ADD_END_DAY_WORK_CHECKIN_REQUEST:
		case types.LOAD_SURVEY_REQUEST:
		case types.SEND_ANSWER_REQUEST:
		case types.UPLOAD_EMPLOYEE_PROFILE_PHOTO_REQUEST:
		case types.DELETE_EMPLOYEE_PROFILE_PHOTO_REQUEST:
		case types.SAVE_EMAIL_REQUEST:
		case types.LOGOUT_REQUEST:
			return {
				...state,
				loading: true
			}

		case types.LOGIN_SUCCESS:
			return {
				...state,
				loading: false,
				isAuthenticated: true,
				employee: actions.payload.employee,
				todayEntered: actions.payload.todayEntered,
				lastCheckin: actions.payload.lastCheckin,
				workWithSystem: actions.payload.todayEntered
			}

		case types.FETCH_USER_TOKEN_SUCCESS:
			return {
				...state,
				employee: actions.payload
			}

		case types.FETCH_USER_SUCCESS:
			return {
				...state,
				loading: false,
				employee: actions.payload.employee,
				isAuthenticated: true,
				todayEntered: actions.payload.todayEntered,
				lastCheckin: actions.payload.lastCheckin,
				workWithSystem: actions.payload.todayEntered ?? false,
				version: actions.payload.version
			}

		case types.LOGOUT_SUCCESS:
			return {
				...initialState
			}

		case types.ADD_WORK_CHECKIN_SUCCESS:
			return {
				...state,
				loading: false,
				todayEntered: true,
				lastCheckin: actions.payload
			}

		case types.UPLOAD_EMPLOYEE_PROFILE_PHOTO_SUCCESS:
			return {
				...state,
				employee: actions.payload,
				loading: false
			}

		case types.DELETE_EMPLOYEE_PROFILE_PHOTO_SUCCESS:
			return {
				...state,
				employee: actions.payload,
				loading: false
			}

		case types.ADD_END_DAY_WORK_CHECKIN_SUCCESS:
			return {
				...state,
				loading: false,
				lastCheckin: actions.payload
			}

		case types.LOAD_SURVEY_SUCCESS:
			return {
				...state,
				loading: false,
				survey: actions.payload
			}

		case types.SEND_ANSWER_SUCCESS:
			return {
				...state,
				loading: false,
				survey: initialState.survey
			}

		case types.WORK_WITH_SYSTEM:
			return {
				...state,
				loading: false,
				workWithSystem: true
			}

		case types.CLEAR_USER:
			localStorage.removeItem('access_token')

			return {
				...initialState
			}

		case types.EVENTS_DISABLE:
			return { ...state, eventsDisabled: true }

		case types.EVENTS_ENABLE:
			return { ...state, eventsDisabled: false }

		case types.SAVE_EMAIL_SUCCESS:
			return {
				...state,
				employee: actions.payload,
				loading: false
			}

		case types.LOGIN_ERROR:
		case types.FETCH_USER_ERROR:
		case types.LOGOUT_ERROR:
		case types.ADD_WORK_CHECKIN_ERROR:
		case types.ADD_END_DAY_WORK_CHECKIN_ERROR:
		case types.LOAD_SURVEY_ERROR:
		case types.SEND_ANSWER_ERROR:
		case types.UPLOAD_EMPLOYEE_PROFILE_PHOTO_ERROR:
		case types.DELETE_EMPLOYEE_PROFILE_PHOTO_ERROR:
		case types.SAVE_EMAIL_ERROR:
			return {
				...state,
				loading: false,
				error: true
			}

		default:
			return state
	}
}
