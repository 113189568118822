import * as checkins from './checkins'
import * as conceptArts from './conceptArts'
import * as concepts from './concepts'
import * as employees from './employees'
import * as finance from './finance'
import * as invoices from './invoices'
import * as financeOperations from './financeOperations'
import * as subscriptions from './subscriptions'
import * as projects from './projects'
import * as promotions from './promotions'
import * as publishers from './publishers'
import * as sicknesses from './sicknesses'
import * as statistics from './statistics'
import * as trainingProjects from './trainingProjects'
import * as vacations from './vacations'
import * as visits from './visits'
import * as ideas from './ideas'
import * as surveys from './surveys'
import * as parties from './parties'
import * as todolists from './todolists'
import * as general from './general'
import * as salariesTable from './salariesTable'
import * as systemSettings from './systemSettings'
import * as kpi from './kpi'
import * as stages from './recruiting/stages'
import * as funnels from './recruiting/funnels'
import * as candidates from './recruiting/candidates'
import * as vacancies from './recruiting/vacancies'
import * as applications from './recruiting/applications'
import * as hiringItems from './recruiting/hiring.items'
import * as departments from './recruiting/departments'
import * as refusals from './recruiting/refusals'
import * as candidates_sources from './recruiting/candidates.sources'
import * as recruiting_statistic from './recruiting/statistic'
import * as notifications from './notifications'

const dataActions = {
	checkins,
	conceptArts,
	concepts,
	employees,
	finance,
	invoices,
	financeOperations,
	subscriptions,
	projects,
	promotions,
	publishers,
	sicknesses,
	statistics,
	trainingProjects,
	vacations,
	visits,
	ideas,
	surveys,
	parties,
	todolists,
	general,
	salariesTable,
	systemSettings,
	kpi,
	stages,
	funnels,
	candidates,
	vacancies,
	applications,
	hiringItems,
	departments,
	refusals,
	candidates_sources,
	recruiting_statistic,
	notifications
}

export default dataActions
