import { toastr } from 'react-redux-toastr'
import actions from 'redux/actions'
import { fetchCurrentUser, logout, setEmail } from 'utils/api.request'
import Employee, { EmailData } from 'types/employee'
import InterfaceSettings from 'types/interfaceSettings'
import { uploadInterfaceSettings } from 'redux/actions/interfaceSettingsActions'
import operations from '..'
import { FetchUserStatus } from 'types/user'

export const loginFunc = (username, password) => async (dispatch) => {
	dispatch(actions.userActions.loginRequest())
	try {
		/* const loginInfo: LoginUserStatus = await login(username, password)

		localStorage.setItem('access_token', loginInfo.token)
 */
		const storageData: string | null = localStorage.getItem('interface_settings') as string
		if (storageData) {
			const interfaceSettings: InterfaceSettings = JSON.parse(storageData)
			dispatch(uploadInterfaceSettings(interfaceSettings))
		}

		//dispatch(actions.userActions.loginSuccess(loginInfo))

		await dispatch(operations.userOperations.surveys.loadSurveyFunc())
	} catch (error: any) {
		toastr.error('', 'Помилка входу')
		return dispatch(actions.userActions.loginError())
	}
}

export const fetchUserFunc = () => async (dispatch) => {
	dispatch(actions.userActions.fetchUserRequest())

	/* 	const token = localStorage.getItem('access_token')
	if (token === null) return dispatch(actions.userActions.fetchUserError())

	const userInfo = jwt<Employee>(token)
	dispatch(actions.userActions.fetchCurrentUserTokenSuccess(userInfo)) */

	const storageData: string | null = localStorage.getItem('interface_settings') as string
	if (storageData) {
		const interfaceSettings: InterfaceSettings = JSON.parse(storageData)
		dispatch(uploadInterfaceSettings(interfaceSettings))
	}

	try {
		const userInfo: FetchUserStatus = await fetchCurrentUser()
		dispatch(operations.userOperations.surveys.loadSurveyFunc())
		dispatch(actions.userActions.fetchUserSuccess(userInfo))

		return userInfo
	} catch (error: any) {
		/* console.log(error)
		toastr.error('', 'Помилка у завантаженні користувача, переувійдіть у систему')
		localStorage.clear()
		dispatch(clearUser()) */

		dispatch(actions.userActions.fetchUserError())
		return false
	}
}

export const saveEmailFunc = (emailData: EmailData) => async (dispatch) => {
	dispatch(actions.userActions.saveEmailRequest())
	try {
		const employee: Employee = await setEmail(emailData)

		toastr.success('', 'Дякуємо! Пошта збережена')

		return dispatch(actions.userActions.saveEmailSuccess(employee))
	} catch (error: any) {
		toastr.error('', 'Помилка збереження пошти')
		return dispatch(actions.userActions.saveEmailError())
	}
}

export const logoutFunc = () => async (dispatch) => {
	dispatch(actions.userActions.logoutRequest())

	try {
		await logout()

		dispatch(actions.userActions.logoutSuccess())

		return true
	} catch (error: any) {
		toastr.error('', 'Помилка виходу')
		dispatch(actions.userActions.logoutError())
		return false
	}
}
