import { createAction } from '@reduxjs/toolkit'
import * as types from '../../../constants'
import { Application } from 'types/recruiting/application'
import { ApplicationVacancyRequest } from 'types/recruiting/vacancy'

export const loadApplicationsRequest = createAction(types.LOAD_APPLICATIONS_REQUEST)
export const loadApplicationsSuccess = createAction<Application[]>(types.LOAD_APPLICATIONS_SUCCESS)
export const loadApplicationsError = createAction(types.LOAD_APPLICATIONS_ERROR)

export const createApplicationRequest = createAction(types.CREATE_APPLICATION_REQUEST)
export const createApplicationSuccess = createAction<Application>(types.CREATE_APPLICATION_SUCCESS)
export const createApplicationError = createAction(types.CREATE_APPLICATION_ERROR)

export const editApplicationRequest = createAction(types.EDIT_APPLICATION_REQUEST)
export const editApplicationSuccess = createAction<Application>(types.EDIT_APPLICATION_SUCCESS)
export const editApplicationError = createAction(types.EDIT_APPLICATION_ERROR)

export const acceptApplicationRequest = createAction(types.ACCEPT_APPLICATION_REQUEST)
export const acceptApplicationSuccess = createAction<ApplicationVacancyRequest>(
	types.ACCEPT_APPLICATION_SUCCESS
)
export const acceptApplicationError = createAction(types.ACCEPT_APPLICATION_ERROR)

export const rejectApplicationRequest = createAction(types.REJECT_APPLICATION_REQUEST)
export const rejectApplicationSuccess = createAction<Application>(types.REJECT_APPLICATION_SUCCESS)
export const rejectApplicationError = createAction(types.REJECT_APPLICATION_ERROR)

export const archiveApplicationRequest = createAction(types.ARCHIVE_APPLICATION_REQUEST)
export const archiveApplicationSuccess = createAction<Application>(
	types.ARCHIVE_APPLICATION_SUCCESS
)
export const archiveApplicationError = createAction(types.ARCHIVE_APPLICATION_ERROR)
