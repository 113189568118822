import React from 'react'
import { Container, Spinner } from 'react-bootstrap'

const Loader = (props: { size?: 'sm' | 'lg' }) => (
	<Container
		fluid
		className={`${
			props.size === 'sm' ? 'm-2' : 'vh-50'
		} d-flex justify-content-center align-items-center`}
	>
		<Spinner animation="grow" variant={'primary'} className="me-2" />
	</Container>
)

export default Loader
