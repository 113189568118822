import { createAction } from '@reduxjs/toolkit'
import * as types from '../../../constants'
import { Refusal } from 'types/recruiting/refusal'

export const loadRefusalsRequest = createAction(types.LOAD_REFUSALS_REQUEST)
export const loadRefusalsSuccess = createAction<Refusal[]>(types.LOAD_REFUSALS_SUCCESS)
export const loadRefusalsError = createAction(types.LOAD_REFUSALS_ERROR)

export const createRefusalRequest = createAction(types.CREATE_REFUSAL_REQUEST)
export const createRefusalSuccess = createAction<Refusal>(types.CREATE_REFUSAL_SUCCESS)
export const createRefusalError = createAction(types.CREATE_REFUSAL_ERROR)

export const archiveRefusalRequest = createAction(types.ARCHIVE_REFUSAL_REQUEST)
export const archiveRefusalSuccess = createAction<Refusal>(types.ARCHIVE_REFUSAL_SUCCESS)
export const archiveRefusalError = createAction(types.ARCHIVE_REFUSAL_ERROR)
