import {
	createConceptVisitCheck,
	createProjectVisitCheck,
	fixProjectVisits,
	loadVisits
} from 'utils/api.request'

import { toastr } from 'react-redux-toastr'
import actions from 'redux/actions'
import { t } from 'i18next'
import { ConceptVisitCreationData, FixCheckData, ProjectVisitCreationData } from 'types/visitCheck'

export const loadVisitsFunc = () => async (dispatch) => {
	dispatch(actions.dataActions.visits.loadVisitsRequest())
	try {
		const loadedVisits = await loadVisits()
		return dispatch(actions.dataActions.visits.loadVisitsSuccess(loadedVisits))
	} catch (error: any) {
		toastr.error('Error', error.response.data.message)
		return dispatch(actions.dataActions.visits.loadVisitsError())
	}
}

export const createProjectVisitCheckFunc =
	(checkInfo: ProjectVisitCreationData, project: string) => async (dispatch) => {
		dispatch(actions.dataActions.visits.createProjectVisitCheckRequest())
		try {
			const updatedVisits = await createProjectVisitCheck(checkInfo)

			toastr.success('', t(`CheckVisitModal_${checkInfo.position}`))
			return dispatch(
				actions.dataActions.visits.createProjectVisitCheckSuccess({
					visits: updatedVisits,
					project: project
				})
			)
		} catch (error: any) {
			toastr.error('Error', error.message)
			return dispatch(actions.dataActions.visits.createProjectVisitCheckError())
		}
	}

export const createConceptVisitCheckFunc =
	(checkInfo: ConceptVisitCreationData, concept: string) => async (dispatch) => {
		dispatch(actions.dataActions.visits.createConceptVisitCheckRequest())
		try {
			const updatedVisits = await createConceptVisitCheck(checkInfo)

			toastr.success('', t('CheckVisitModal_producer'))
			return dispatch(
				actions.dataActions.visits.createConceptVisitCheckSuccess({
					visits: updatedVisits,
					concept: concept
				})
			)
		} catch (error: any) {
			toastr.error('Error', error.response.data.message)
			return dispatch(actions.dataActions.visits.createConceptVisitCheckError())
		}
	}

export const fixProjectVisitsFunc = (fixCheckData: FixCheckData) => async (dispatch) => {
	dispatch(actions.dataActions.visits.fixProjectVisitsRequest())
	try {
		const fixedVisits = await fixProjectVisits(fixCheckData)

		toastr.success('', t(`reduxToastrSuccess_fixSuccess`))
		return dispatch(
			actions.dataActions.visits.fixProjectVisitsSuccess({
				visits: fixedVisits,
				project: fixCheckData.project_id
			})
		)
	} catch (error: any) {
		toastr.error('Error', error.message)
		return dispatch(actions.dataActions.visits.createProjectVisitCheckError())
	}
}
