import { toastr } from 'react-redux-toastr'
import actions from 'redux/actions'
//import { t } from 'i18next'
import { SystemNotification } from 'types/notification'
import { loadEmployeeNotifications, updateNotificationStatus } from 'utils/api.request'

export const loadEmployeeNotificationsFunc = () => async (dispatch) => {
	dispatch(actions.dataActions.notifications.loadEmployeeNotificationsRequest())
	try {
		const employeeNotifications: SystemNotification[] = await loadEmployeeNotifications()

		return dispatch(
			actions.dataActions.notifications.loadEmployeeNotificationsSuccess(
				employeeNotifications
			)
		)
	} catch (error: any) {
		toastr.error('Error', error.response.data.message)
		return dispatch(actions.dataActions.notifications.loadEmployeeNotificationsError())
	}
}

export const updateNotificationStatusFunc = (id: string) => async (dispatch) => {
	dispatch(actions.dataActions.notifications.updateNotificationStatusRequest())
	try {
		const updatedNotification: SystemNotification = await updateNotificationStatus(id)

		return dispatch(
			actions.dataActions.notifications.updateNotificationStatusSuccess(updatedNotification)
		)
	} catch (error: any) {
		toastr.error('Error', error.response.data.message)
		return dispatch(actions.dataActions.notifications.updateNotificationStatusError())
	}
}
