import axios from 'axios'

const api = (download?: boolean) => {
	return axios.create({
		baseURL: `${window.location.protocol}//${process.env.REACT_APP_API_URL}`,
		responseType: download ? 'blob' : 'json',
		withCredentials: true,
		headers: {
			'Content-Type': 'application/json',
			Accept: download ? 'application/octet-stream' : 'application/json'
		}
	})
}

export default api
