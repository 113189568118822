import { createAction } from '@reduxjs/toolkit'
import Project, {
	DoneProjectsPayload,
	ProjectCount,
	ProjectEmployees,
	ProjectRolesEnum,
	ProjectWithIteration
} from 'types/project'
import * as types from '../../constants'

export const loadProjectsRequest = createAction(types.LOAD_PROJECTS_REQUEST)
export const loadProjectsSuccess = createAction<Project[]>(types.LOAD_PROJECTS_SUCCESS)
export const loadProjectsError = createAction(types.LOAD_PROJECTS_ERROR)

export const loadEmployeeProjectsRequest = createAction(types.LOAD_EMPLOYEE_PROJECTS_REQUEST)
export const loadEmployeeProjectsSuccess = createAction<Project[]>(
	types.LOAD_EMPLOYEE_PROJECTS_SUCCESS
)
export const loadEmployeeProjectsError = createAction(types.LOAD_EMPLOYEE_PROJECTS_ERROR)

export const loadProjectsEmployeesRequest = createAction(types.LOAD_PROJECTS_EMPLOYEES_REQUEST)
export const loadProjectsEmployeesSuccess = createAction<ProjectEmployees[]>(
	types.LOAD_PROJECTS_EMPLOYEES_SUCCESS
)
export const loadProjectsEmployeesError = createAction(types.LOAD_PROJECTS_EMPLOYEES_ERROR)

export const loadShortProjectsRequest = createAction(types.LOAD_SHORT_PROJECTS_REQUEST)
export const loadShortProjectsSuccess = createAction<Project[]>(types.LOAD_SHORT_PROJECTS_SUCCESS)
export const loadShortProjectsError = createAction(types.LOAD_SHORT_PROJECTS_ERROR)

export const loadPublisherProjectsRequest = createAction(types.LOAD_PUBLISHER_PROJECTS_REQUEST)
export const loadPublisherProjectsSuccess = createAction<Project[]>(
	types.LOAD_PUBLISHER_PROJECTS_SUCCESS
)
export const loadPublisherProjectsError = createAction(types.LOAD_PUBLISHER_PROJECTS_ERROR)

export const getInTestingProjectsRequest = createAction(types.GET_IN_TESTING_PROJECTS_REQUEST)
export const getInTestingProjectsSuccess = createAction<DoneProjectsPayload>(
	types.GET_IN_TESTING_PROJECTS_SUCCESS
)
export const getInTestingProjectsError = createAction(types.GET_IN_TESTING_PROJECTS_ERROR)

export const getTestedProjectsRequest = createAction(types.GET_TESTED_PROJECTS_REQUEST)
export const getTestedProjectsSuccess = createAction<DoneProjectsPayload>(
	types.GET_TESTED_PROJECTS_SUCCESS
)
export const getTestedProjectsError = createAction(types.GET_TESTED_PROJECTS_ERROR)

export const getProjectRequest = createAction(types.GET_PROJECT_REQUEST)
export const getProjectSuccess = createAction<Project>(types.GET_PROJECT_SUCCESS)
export const getProjectError = createAction(types.GET_PROJECT_ERROR)

export const getMembersProjectCountRequest = createAction(types.GET_MEMBERS_PROJECT_COUNT_REQUEST)
export const getMembersProjectCountSuccess = createAction<{
	role: ProjectRolesEnum
	data: ProjectCount[]
}>(types.GET_MEMBERS_PROJECT_COUNT_SUCCESS)
export const getMembersProjectCountError = createAction(types.GET_MEMBERS_PROJECT_COUNT_ERROR)

export const createProjectRequest = createAction(types.CREATE_PROJECT_REQUEST)
export const createProjectSuccess = createAction<ProjectWithIteration>(types.CREATE_PROJECT_SUCCESS)
export const createProjectError = createAction(types.CREATE_PROJECT_ERROR)

export const updateProjectRequest = createAction(types.UPDATE_PROJECT_REQUEST)
export const updateProjectSuccess = createAction<Project>(types.UPDATE_PROJECT_SUCCESS)
export const updateProjectError = createAction(types.UPDATE_PROJECT_ERROR)

export const sendProjectResultsRequest = createAction(types.SEND_PROJECT_RESULTS_REQUEST)
export const sendProjectResultsSuccess = createAction<Project>(types.SEND_PROJECT_RESULTS_SUCCESS)
export const sendProjectResultsError = createAction(types.SEND_PROJECT_RESULTS_ERROR)

export const addGDDProjectRequest = createAction(types.ADD_GDD_PROJECT_REQUEST)
export const addGDDProjectSuccess = createAction<Project>(types.ADD_GDD_PROJECT_SUCCESS)
export const addGDDProjectError = createAction(types.ADD_GDD_PROJECT_ERROR)

export const techSetupProjectRequest = createAction(types.TECH_SETUP_PROJECT_REQUEST)
export const techSetupProjectSuccess = createAction<Project>(types.TECH_SETUP_PROJECT_SUCCESS)
export const techSetupProjectError = createAction(types.TECH_SETUP_PROJECT_ERROR)

export const mvpDoneProjectRequest = createAction(types.MVP_DONE_PROJECT_REQUEST)
export const mvpDoneProjectSuccess = createAction<Project>(types.MVP_DONE_PROJECT_SUCCESS)
export const mvpDoneProjectError = createAction(types.MVP_DONE_PROJECT_ERROR)

export const qualityApproveProjectRequest = createAction(types.QUALITY_APPROVE_PROJECT_REQUEST)
export const qualityApproveProjectSuccess = createAction<Project>(
	types.QUALITY_APPROVE_PROJECT_SUCCESS
)
export const qualityApproveProjectError = createAction(types.QUALITY_APPROVE_PROJECT_ERROR)

export const releaseDoneProjectRequest = createAction(types.RELEASE_DONE_PROJECT_REQUEST)
export const releaseDoneProjectSuccess = createAction<Project>(types.RELEASE_DONE_PROJECT_SUCCESS)
export const releaseDoneProjectError = createAction(types.RELEASE_DONE_PROJECT_ERROR)

export const pauseBurnRateProjectRequest = createAction(types.PAUSE_BURN_RATE_PROJECT_REQUEST)
export const pauseBurnRateProjectSuccess = createAction<Project>(
	types.PAUSE_BURN_RATE_PROJECT_SUCCESS
)
export const pauseBurnRateProjectError = createAction(types.PAUSE_BURN_RATE_PROJECT_ERROR)

export const continueBurnRateProjectRequest = createAction(types.CONTINUE_BURN_RATE_PROJECT_REQUEST)
export const continueBurnRateProjectSuccess = createAction<Project>(
	types.CONTINUE_BURN_RATE_PROJECT_SUCCESS
)
export const continueBurnRateProjectError = createAction(types.CONTINUE_BURN_RATE_PROJECT_ERROR)

export const deleteProjectRequest = createAction(types.DELETE_PROJECT_REQUEST)
export const deleteProjectSuccess = createAction<string>(types.DELETE_PROJECT_SUCCESS)
export const deleteProjectError = createAction(types.DELETE_PROJECT_ERROR)
