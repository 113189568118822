import { createAction } from '@reduxjs/toolkit'
import * as types from '../../../constants'
import { Vacancy } from 'types/recruiting/vacancy'

export const loadVacanciesRequest = createAction(types.LOAD_VACANCIES_REQUEST)
export const loadVacanciesSuccess = createAction<Vacancy[]>(types.LOAD_VACANCIES_SUCCESS)
export const loadVacanciesError = createAction(types.LOAD_VACANCIES_ERROR)

export const getVacancyRequest = createAction(types.GET_VACANCY_REQUEST)
export const getVacancySuccess = createAction<Vacancy>(types.GET_VACANCY_SUCCESS)
export const getVacancyError = createAction(types.GET_VACANCY_ERROR)

export const createVacancyRequest = createAction(types.CREATE_VACANCY_REQUEST)
export const createVacancySuccess = createAction<Vacancy>(types.CREATE_VACANCY_SUCCESS)
export const createVacancyError = createAction(types.CREATE_VACANCY_ERROR)

export const editVacancyRequest = createAction(types.EDIT_VACANCY_REQUEST)
export const editVacancySuccess = createAction<Vacancy>(types.EDIT_VACANCY_SUCCESS)
export const editVacancyError = createAction(types.EDIT_VACANCY_ERROR)

export const pauseVacancyRequest = createAction(types.PAUSE_VACANCY_REQUEST)
export const pauseVacancySuccess = createAction<Vacancy>(types.PAUSE_VACANCY_SUCCESS)
export const pauseVacancyError = createAction(types.PAUSE_VACANCY_ERROR)

export const continueVacancyRequest = createAction(types.CONTINUE_VACANCY_REQUEST)
export const continueVacancySuccess = createAction<Vacancy>(types.CONTINUE_VACANCY_SUCCESS)
export const continueVacancyError = createAction(types.CONTINUE_VACANCY_ERROR)

export const updateRecruitersRequest = createAction(types.UPDATE_RECRUITERS_REQUEST)
export const updateRecruitersSuccess = createAction<Vacancy>(types.UPDATE_RECRUITERS_SUCCESS)
export const updateRecruitersError = createAction(types.UPDATE_RECRUITERS_ERROR)

export const deleteRecruiterRequest = createAction(types.DELETE_RECRUITER_REQUEST)
export const deleteRecruiterSuccess = createAction<Vacancy>(types.DELETE_RECRUITER_SUCCESS)
export const deleteRecruiterError = createAction(types.DELETE_RECRUITER_ERROR)

export const updateRequestersRequest = createAction(types.UPDATE_REQUESTERS_REQUEST)
export const updateRequestersSuccess = createAction<Vacancy>(types.UPDATE_REQUESTERS_SUCCESS)
export const updateRequestersError = createAction(types.UPDATE_REQUESTERS_ERROR)

export const deleteRequesterRequest = createAction(types.DELETE_REQUESTER_REQUEST)
export const deleteRequesterSuccess = createAction<Vacancy>(types.DELETE_REQUESTER_SUCCESS)
export const deleteRequesterError = createAction(types.DELETE_REQUESTER_ERROR)

export const closeVacancyRequest = createAction(types.CLOSE_VACANCY_REQUEST)
export const closeVacancySuccess = createAction<Vacancy>(types.CLOSE_VACANCY_SUCCESS)
export const closeVacancyError = createAction(types.CLOSE_VACANCY_ERROR)

export const archiveVacancyRequest = createAction(types.ARCHIVE_VACANCY_REQUEST)
export const archiveVacancySuccess = createAction<Vacancy>(types.ARCHIVE_VACANCY_SUCCESS)
export const archiveVacancyError = createAction(types.ARCHIVE_VACANCY_ERROR)
