import { toastr } from 'react-redux-toastr'
import actions from 'redux/actions'
import { t } from 'i18next'
import {
	archiveCandidate,
	createCandidate,
	loadCandidates,
	takeOnVacancyCandidate,
	updateCandidate,
	updateCandidatesMarks
} from 'utils/api.request'
import {
	Candidate,
	CandidateCreationData,
	CandidateUpdatingData,
	CandidatesMarksUpdatingData,
	TakeOnVacancyCreationData
} from 'types/recruiting/candidate'

export const loadCandidatesFunc = () => async (dispatch) => {
	dispatch(actions.dataActions.candidates.loadCandidatesRequest())
	try {
		const allCandidates: Candidate[] = await loadCandidates()

		return dispatch(actions.dataActions.candidates.loadCandidatesSuccess(allCandidates))
	} catch (error: any) {
		toastr.error('Error', error.response.data.message)
		return dispatch(actions.dataActions.candidates.loadCandidatesError())
	}
}

export const createCandidateFunc = (creationData: CandidateCreationData) => async (dispatch) => {
	dispatch(actions.dataActions.candidates.createCandidateRequest())
	try {
		const newCandidate: Candidate = await createCandidate(creationData)

		toastr.success('', t('reduxToastrSuccess_createCandidate'))
		return dispatch(actions.dataActions.candidates.createCandidateSuccess(newCandidate))
	} catch (error: any) {
		toastr.error('Error', error.response.data.message)
		return dispatch(actions.dataActions.candidates.createCandidateError())
	}
}

export const updateCandidateFunc = (creationData: CandidateUpdatingData) => async (dispatch) => {
	dispatch(actions.dataActions.candidates.updateCandidateRequest())
	try {
		const updatedCandidate: Candidate = await updateCandidate(creationData)

		toastr.success('', t('reduxToastrSuccess_updateCandidate'))
		return dispatch(actions.dataActions.candidates.updateCandidateSuccess(updatedCandidate))
	} catch (error: any) {
		toastr.error('Error', error.response.data.message)
		return dispatch(actions.dataActions.candidates.updateCandidateError())
	}
}

export const takeCandidateOnVacancyFunc =
	(creationData: TakeOnVacancyCreationData) => async (dispatch) => {
		dispatch(actions.dataActions.candidates.takeCandidateOnVacancyRequest())
		try {
			const updatedCandidate: Candidate = await takeOnVacancyCandidate(creationData)

			toastr.success('', t('reduxToastrSuccess_takeCandidateOnVacancy'))
			return dispatch(
				actions.dataActions.candidates.takeCandidateOnVacancySuccess(updatedCandidate)
			)
		} catch (error: any) {
			toastr.error('Error', error.response.data.message)
			return dispatch(actions.dataActions.candidates.takeCandidateOnVacancyError())
		}
	}

export const updateCandidatesMarksFunc =
	(updatingData: CandidatesMarksUpdatingData, inVacancy: boolean) => async (dispatch) => {
		dispatch(actions.dataActions.candidates.updateCandidatesMarksRequest())
		try {
			const updatedCandidate: Candidate = await updateCandidatesMarks(updatingData)

			toastr.success('', t('reduxToastrSuccess_updateCandidatesMarks'))

			if (inVacancy) {
				return dispatch(
					actions.dataActions.candidates.updateVacancyCandidatesMarksSuccess(
						updatedCandidate
					)
				)
			} else {
				return dispatch(
					actions.dataActions.candidates.updateCandidatesMarksSuccess(updatedCandidate)
				)
			}
		} catch (error: any) {
			toastr.error('Error', error.response.data.message)
			return dispatch(actions.dataActions.candidates.updateCandidatesMarksError())
		}
	}

export const archiveCandidateFunc = (candidate_id: string) => async (dispatch) => {
	dispatch(actions.dataActions.candidates.archiveCandidateRequest())
	try {
		const archivedCandidate: Candidate = await archiveCandidate(candidate_id)

		toastr.success('', t('reduxToastrSuccess_archiveCandidate'))
		return dispatch(actions.dataActions.candidates.archiveCandidateSuccess(archivedCandidate))
	} catch (error: any) {
		toastr.error('Error', error.response.data.message)
		return dispatch(actions.dataActions.candidates.archiveCandidateError())
	}
}
