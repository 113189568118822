import React from 'react'
import { useTranslation } from 'react-i18next'
import ReduxToastr from 'react-redux-toastr'
import Routes from './routes/Routes'
import { Helmet } from 'react-helmet'

const App = () => {
	const { t } = useTranslation()

	return (
		<>
			<Helmet>
				<title>{t('ASYA')}</title>
			</Helmet>

			<Routes />

			<ReduxToastr
				timeOut={5000}
				newestOnTop={true}
				position="top-right"
				transitionIn="fadeIn"
				transitionOut="fadeOut"
				progressBar
				closeOnToastrClick
			/>
		</>
	)
}

export default App
