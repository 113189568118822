import { toastr } from 'react-redux-toastr'
import actions from 'redux/actions'
import { DepartmentStatistic } from 'types/recruiting/statistic'
import { loadDayStatistic } from 'utils/api.request'

export const loadDayStatisticFunc = () => async (dispatch) => {
	dispatch(actions.dataActions.recruiting_statistic.loadDayStatisticRequest())
	try {
		const dayDtatistic: DepartmentStatistic[] = await loadDayStatistic()

		return dispatch(
			actions.dataActions.recruiting_statistic.loadDayStatisticSuccess(dayDtatistic)
		)
	} catch (error: any) {
		toastr.error('Error', error.response.data.message)
		return dispatch(actions.dataActions.recruiting_statistic.loadDayStatisticError())
	}
}
