import { toastr } from 'react-redux-toastr'
import actions from 'redux/actions'
import { t } from 'i18next'
import {
	archiveVacancy,
	closeVacancy,
	continueVacancy,
	createVacancy,
	deleteRecruiter,
	deleteRequester,
	editVacancy,
	getVacancy,
	loadVacancies,
	pauseVacancy,
	updateRecruiters,
	updateRequesters
} from 'utils/api.request'
import {
	RecruiterDeletingData,
	RecruitersUpdatingData,
	RequesterDeletingData,
	RequestersUpdatingData,
	Vacancy,
	VacancyClosingData,
	VacancyCreationData,
	VacancyEditData
} from 'types/recruiting/vacancy'

export const loadVacanciesFunc = () => async (dispatch) => {
	dispatch(actions.dataActions.vacancies.loadVacanciesRequest())
	try {
		const allVacancies: Vacancy[] = await loadVacancies()

		return dispatch(actions.dataActions.vacancies.loadVacanciesSuccess(allVacancies))
	} catch (error: any) {
		toastr.error('Error', error.response.data.message)
		return dispatch(actions.dataActions.vacancies.loadVacanciesError())
	}
}

export const getVacancyFunc = (id: string) => async (dispatch) => {
	dispatch(actions.dataActions.vacancies.getVacancyRequest())
	try {
		const vacancy: Vacancy = await getVacancy(id)

		return dispatch(actions.dataActions.vacancies.getVacancySuccess(vacancy))
	} catch (error: any) {
		toastr.error('Error', error.response.data.message)
		return dispatch(actions.dataActions.vacancies.getVacancyError())
	}
}

export const createVacancyFunc = (creationData: VacancyCreationData) => async (dispatch) => {
	dispatch(actions.dataActions.vacancies.createVacancyRequest())
	try {
		const newVacancy: Vacancy = await createVacancy(creationData)

		toastr.success('', t('reduxToastrSuccess_createVacancy'))

		return dispatch(actions.dataActions.vacancies.createVacancySuccess(newVacancy))
	} catch (error: any) {
		toastr.error('Error', error.response.data.message)
		return dispatch(actions.dataActions.vacancies.createVacancyError())
	}
}

export const editVacancyFunc = (vacancyEditData: VacancyEditData) => async (dispatch) => {
	dispatch(actions.dataActions.vacancies.editVacancyRequest())
	try {
		let editedVacancy: Vacancy = await editVacancy(vacancyEditData)

		toastr.success('', t('reduxToastrSuccess_editVacancy'))

		return dispatch(actions.dataActions.vacancies.editVacancySuccess(editedVacancy))
	} catch (error: any) {
		toastr.error('Error', error.response.data.message)
		return dispatch(actions.dataActions.vacancies.editVacancyError())
	}
}

export const updateRecruitersFunc =
	(recruitersUpdatingData: RecruitersUpdatingData) => async (dispatch) => {
		dispatch(actions.dataActions.vacancies.updateRecruitersRequest())
		try {
			let updatedVacancy: Vacancy = await updateRecruiters(recruitersUpdatingData)

			toastr.success('', t('reduxToastrSuccess_updateRecruitersVacancy'))
			return dispatch(actions.dataActions.vacancies.updateRecruitersSuccess(updatedVacancy))
		} catch (error: any) {
			toastr.error('Error', error.response.data.message)
			return dispatch(actions.dataActions.vacancies.updateRecruitersError())
		}
	}

export const deleteRecruiterFunc =
	(recruiterDeletingData: RecruiterDeletingData) => async (dispatch) => {
		dispatch(actions.dataActions.vacancies.deleteRecruiterRequest())
		try {
			let updatedVacancy: Vacancy = await deleteRecruiter(recruiterDeletingData)

			toastr.success('', t('reduxToastrSuccess_deleteRecruiterVacancy'))
			return dispatch(actions.dataActions.vacancies.deleteRecruiterSuccess(updatedVacancy))
		} catch (error: any) {
			toastr.error('Error', error.response.data.message)
			return dispatch(actions.dataActions.vacancies.deleteRecruiterError())
		}
	}

export const updateRequestersFunc =
	(requestersUpdatingData: RequestersUpdatingData) => async (dispatch) => {
		dispatch(actions.dataActions.vacancies.updateRequestersRequest())
		try {
			let updatedVacancy: Vacancy = await updateRequesters(requestersUpdatingData)

			toastr.success('', t('reduxToastrSuccess_updateRequestersVacancy'))
			return dispatch(actions.dataActions.vacancies.updateRequestersSuccess(updatedVacancy))
		} catch (error: any) {
			toastr.error('Error', error.response.data.message)
			return dispatch(actions.dataActions.vacancies.updateRequestersError())
		}
	}

export const deleteRequesterFunc =
	(requesterDeletingData: RequesterDeletingData) => async (dispatch) => {
		dispatch(actions.dataActions.vacancies.deleteRequesterRequest())
		try {
			let updatedVacancy: Vacancy = await deleteRequester(requesterDeletingData)

			toastr.success('', t('reduxToastrSuccess_deleteRequesterVacancy'))
			return dispatch(actions.dataActions.vacancies.deleteRequesterSuccess(updatedVacancy))
		} catch (error: any) {
			toastr.error('Error', error.response.data.message)
			return dispatch(actions.dataActions.vacancies.deleteRequesterError())
		}
	}

export const pauseVacancyFunc = (vacancy_id: string) => async (dispatch) => {
	dispatch(actions.dataActions.vacancies.pauseVacancyRequest())
	try {
		let pausedVacancy: Vacancy = await pauseVacancy(vacancy_id)

		toastr.success('', t('reduxToastrSuccess_pauseVacancy'))
		return dispatch(actions.dataActions.vacancies.pauseVacancySuccess(pausedVacancy))
	} catch (error: any) {
		toastr.error('Error', error.response.data.message)
		return dispatch(actions.dataActions.vacancies.pauseVacancyError())
	}
}

export const continueVacancyFunc = (vacancy_id: string) => async (dispatch) => {
	dispatch(actions.dataActions.vacancies.continueVacancyRequest())
	try {
		let continuedVacancy: Vacancy = await continueVacancy(vacancy_id)

		toastr.success('', t('reduxToastrSuccess_continueVacancy'))
		return dispatch(actions.dataActions.vacancies.continueVacancySuccess(continuedVacancy))
	} catch (error: any) {
		toastr.error('Error', error.response.data.message)
		return dispatch(actions.dataActions.vacancies.continueVacancyError())
	}
}

export const closeVacancyFunc = (closingData: VacancyClosingData) => async (dispatch) => {
	dispatch(actions.dataActions.vacancies.closeVacancyRequest())
	try {
		let closedVacancy: Vacancy = await closeVacancy(closingData)

		toastr.success('', t('reduxToastrSuccess_closeVacancy'))
		return dispatch(actions.dataActions.vacancies.closeVacancySuccess(closedVacancy))
	} catch (error: any) {
		toastr.error('Error', error.response.data.message)
		return dispatch(actions.dataActions.vacancies.closeVacancyError())
	}
}

export const archiveVacancyFunc = (vacancy_id: string) => async (dispatch) => {
	dispatch(actions.dataActions.vacancies.archiveVacancyRequest())
	try {
		const archivedVacancy: Vacancy = await archiveVacancy(vacancy_id)

		toastr.success('', t('reduxToastrSuccess_archiveVacancy'))
		return dispatch(actions.dataActions.vacancies.archiveVacancySuccess(archivedVacancy))
	} catch (error: any) {
		toastr.error('Error', error.response.data.message)
		return dispatch(actions.dataActions.vacancies.archiveVacancyError())
	}
}
