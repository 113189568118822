const getUser = (state) => state.user
const getUserEmployee = (state) => state.user.employee
const getUserLoading = (state) => state.user.loading
const getUserRoles = (state) => state.user.employee.user_roles
const getIsAuthenticated = (state) => state.user.isAuthenticated
const getTodayEntered = (state) => state.user.todayEntered
const getLastCheckin = (state) => state.user.lastCheckin
const getWorkWithSystem = (state) => state.user.workWithSystem
const getEventsDisabled = (state) => state.user.eventsDisabled
const getSurvey = (state) => state.user.survey
const getMyStat = (state) => state.user.myStat

const getPublishers = (state) => state.data.by_collections.publishers
const getEmployees = (state) => state.data.by_collections.employees

const getProjects = (state) => state.data.by_collections.projects
const getEmployeeProjects = (state) => state.data.by_collections.employee_projects
const getProjectsEmployees = (state) => state.data.by_collections.projects_employees
const getArtistsProjectCount = (state) => state.data.by_collections.project_count.artist
const getDevelopersProjectCount = (state) => state.data.by_collections.project_count.developer
const getInTestingProjectsMonth = (state) => state.data.by_collections.in_testing_projects_month
const getTestedProjectsMonth = (state) => state.data.by_collections.tested_projects_month
const getInTestingProjectsLoading = (state) => state.data.by_collections.in_testing_projects_loading
const getTestedProjectsLoading = (state) => state.data.by_collections.tested_projects_loading

const getInvoices = (state) => state.data.by_collections.invoices
const getSubscriptions = (state) => state.data.by_collections.subscriptions
const getPublishersInvoicePayTime = (state) => state.data.by_collections.publishersInvoicePayTime
const getEmployeePromotions = (state) => state.data.by_collections.employeePromotions
const getPromotions = (state) => state.data.by_collections.promotions
const getTrainingProjects = (state) => state.data.by_collections.trainingProjects

const getFinances = (state) => state.data.finances
const getArtAndDevInMonth = (state) => state.data.finances.artAndDevInMonth
const getProjectsInMonth = (state) => state.data.finances.projectsInMonth
const getEmployeesProfitability = (state) => state.data.finances.employeesProfitability
const getExpenses = (state) => state.data.finances.generalExpenses
const getFinanceOperations = (state) => state.data.finances.operations
const getYearFinanceOperations = (state) => state.data.finances.yearOperations
const getBalanceOperations = (state) => state.data.finances.balanceOperations
const getAdvanceOperations = (state) => state.data.finances.advanceOperations
const getDividends = (state) => state.data.finances.dividends
const getFounders = (state) => state.data.finances.founders
const getSalariesTable = (state) => state.data.finances.salariesTable

const getConcepts = (state) => state.data.by_collections.concepts
const getEmployeeConcepts = (state) => state.data.by_collections.employee_concepts
const getVacations = (state) => state.data.by_collections.vacations
const getEmployeeVacations = (state) => state.data.by_collections.employeeVacations
const getTodayVacations = (state) => state.data.by_collections.todayVacations
const getVacationsLockedPeriod = (state) => state.data.by_collections.vacationLockedPeriods
const getFilter = (state) => state.filter
const getFilterByPublishers = (state) => state.filter.byPublishers
const getIsload = (state) => state.data.by_collections.isLoad
const getLoading = (state) => state.data.by_collections.loading
const financeLoading = (state) => state.data.finances.loading
const getError = (state) => state.data.by_collections.error
const getEmployeeCheckins = (state) => state.data.by_collections.employeeCheckins
const getWorkCheckins = (state) => state.data.by_collections.workchekins
const getEmployeeYearStatistic = (state) => state.data.by_collections.employeeYearStatistic
const getEmployeesYearStatistic = (state) => state.data.by_collections.employeesYearStatistic
const getCompanyStat = (state) => state.data.by_collections.companyStat
const getMentorsStat = (state) => state.data.by_collections.mentorsStat
const getIdeas = (state) => state.data.by_collections.ideas
const getParties = (state) => state.data.by_collections.parties
const getMemberParties = (state) => state.data.by_collections.memberParties
const getVisits = (state) => state.data.by_collections.visits
const getTodolists = (state) => state.data.by_collections.todolists
const getKPI = (state) => state.data.by_collections.kpi_cards

const getSurveys = (state) => state.data.by_collections.weekly_monthly_surveys
const getProjectSurveys = (state) => state.data.by_collections.project_surveys
const getProjectSurveysLoading = (state) => state.data.by_collections.project_surveys_loading
const getProjectSurveysMonth = (state) => state.data.by_collections.project_surveys_month
const getEmployeeProjectSurveys = (state) => state.data.by_collections.employee_project_surveys
const getPartySurveys = (state) => state.data.by_collections.party_surveys
//const getInterfaceSetting = (state) => state.interface_settings

//Balances

const getBalances = (state) => state.data.finances.balances

const getHrBalance = (state) => state.data.finances.hrBalance
const getOfficeBalance = (state) => state.data.finances.officeBalance
const getOtherBalance = (state) => state.data.finances.otherBalance

const getRecruiterHrBalance = (state) => state.data.finances.recruiterHrBalance
const getRecruiterOfficeBalance = (state) => state.data.finances.recruiterOfficeBalance
const getRecruiterOtherBalance = (state) => state.data.finances.recruiterOtherBalance

const getHrHrBalance = (state) => state.data.finances.hrHrBalance
const getHrOfficeBalance = (state) => state.data.finances.hrOfficeBalance
const getHrOtherBalance = (state) => state.data.finances.hrOtherBalance

const getOfficeManagerHrBalance = (state) => state.data.finances.office_managerHrBalance
const getOfficeManagerOfficeBalance = (state) => state.data.finances.office_managerOfficeBalance
const getOfficeManagerOtherBalance = (state) => state.data.finances.office_managerOtherBalance

// Sicknesses
const getSicknesses = (state) => state.data.by_collections.sicknesses
const getTodaySicknesses = (state) => state.data.by_collections.todaySicknesses

// Projects_tab state
const getActiveIdeasBarState = (state) =>
	state.interface_settings.projects_tab.active_ideas_bar.is_open
const getArchivedIdeasBarState = (state) =>
	state.interface_settings.projects_tab.archived_ideas_bar.is_open
const getConceptsBarState = (state) => state.interface_settings.projects_tab.concepts_bar.is_open
const getArchiveConceptsBarState = (state) =>
	state.interface_settings.projects_tab.archive_concepts_bar.is_open
const getDisaprovedConceptsBarState = (state) =>
	state.interface_settings.projects_tab.disaproved_concepts_bar.is_open
const getPinnedConceptsBarState = (state) =>
	state.interface_settings.projects_tab.pinned_concepts_bar.is_open
const getMvpTableBarState = (state) => state.interface_settings.projects_tab.mvp_table_bar.is_open
const getReleasedTableBarState = (state) =>
	state.interface_settings.projects_tab.released_table_bar.is_open
const getInTestTableBarState = (state) =>
	state.interface_settings.projects_tab.inTest_table_bar.is_open
const getTestedtableBarState = (state) =>
	state.interface_settings.projects_tab.tested_table_bar.is_open

// EmploeeFinance_tab state

const getEmployeeFinanceBarState = (state) =>
	state.interface_settings.employee_finance_tab.employee_finance_bar.is_open
const getArtAndDevBarState = (state) =>
	state.interface_settings.employee_finance_tab.art_and_dev_bar.is_open
const getProjectsInMonthBarState = (state) =>
	state.interface_settings.employee_finance_tab.projects_in_month_bar.is_open
const getGeneralExpensesBarState = (state) =>
	state.interface_settings.employee_finance_tab.general_expenses_bar.is_open

// theme
const getSigebar = (state) => state.interface_settings.sidebar
const getCurrentTheme = (state) => state.theme.currentTheme
const getConfetti = (state) => state.interface_settings.confetti

//system settings
const getSystemSettings = (state) => state.data.by_collections.system_settings

//recruiting
const getStages = (state) => state.data.recruiting.stages
const getFunnels = (state) => state.data.recruiting.funnels
const getRefusals = (state) => state.data.recruiting.refusals
const getCandidatesSources = (state) => state.data.recruiting.candidates_sources
const getDepartments = (state) => state.data.recruiting.departments
const getVacancies = (state) => state.data.recruiting.vacancies
const getCandidates = (state) => state.data.recruiting.candidates
const getApplications = (state) => state.data.recruiting.applications
const getRecruitingLoading = (state) => state.data.recruiting.loading
const getDayStatistic = (state) => state.data.recruiting.statistic.by_vacancies_and_departments

//notifications
const getEmployeeNotifications = (state) => state.data.by_collections.notifications

const selectors = {
	getUser,
	getUserEmployee,
	getUserLoading,
	getUserRoles,
	getIsAuthenticated,
	getTodayEntered,
	getLastCheckin,
	getWorkWithSystem,
	getEventsDisabled,
	getSurvey,
	getEmployeeCheckins,
	getMyStat,

	getPublishers,
	getEmployees,
	getArtAndDevInMonth,
	getProjects,
	getEmployeeProjects,
	getProjectsEmployees,
	getArtistsProjectCount,
	getDevelopersProjectCount,
	getInTestingProjectsMonth,
	getTestedProjectsMonth,
	getInTestingProjectsLoading,
	getTestedProjectsLoading,

	getProjectsInMonth,
	getExpenses,
	getEmployeeYearStatistic,
	getEmployeesYearStatistic,
	getCompanyStat,
	getMentorsStat,
	getIdeas,
	getParties,
	getMemberParties,
	getVisits,
	getTodolists,
	getKPI,

	getSurveys,
	getProjectSurveys,
	getProjectSurveysLoading,
	getProjectSurveysMonth,
	getEmployeeProjectSurveys,
	getPartySurveys,

	getBalances,

	getHrBalance,
	getOfficeBalance,
	getOtherBalance,

	getRecruiterHrBalance,
	getRecruiterOfficeBalance,
	getRecruiterOtherBalance,

	getHrHrBalance,
	getHrOfficeBalance,
	getHrOtherBalance,

	getOfficeManagerHrBalance,
	getOfficeManagerOfficeBalance,
	getOfficeManagerOtherBalance,

	getConcepts,
	getEmployeeConcepts,
	getInvoices,
	getSubscriptions,
	getPublishersInvoicePayTime,
	getEmployeePromotions,
	getPromotions,
	getTrainingProjects,

	getFinances,
	getFinanceOperations,
	getYearFinanceOperations,
	getBalanceOperations,
	getAdvanceOperations,
	getDividends,
	getFounders,
	getSalariesTable,

	getEmployeesProfitability,
	getVacations,
	getEmployeeVacations,
	getTodayVacations,
	getVacationsLockedPeriod,
	getFilter,
	getFilterByPublishers,
	getIsload,
	getLoading,
	financeLoading,
	getError,
	getWorkCheckins,
	getSicknesses,
	getTodaySicknesses,

	getActiveIdeasBarState,
	getArchivedIdeasBarState,
	getConceptsBarState,
	getArchiveConceptsBarState,
	getDisaprovedConceptsBarState,
	getPinnedConceptsBarState,
	getMvpTableBarState,
	getReleasedTableBarState,
	getInTestTableBarState,
	getTestedtableBarState,
	getEmployeeFinanceBarState,
	getArtAndDevBarState,
	getProjectsInMonthBarState,
	getGeneralExpensesBarState,

	getSigebar,
	getCurrentTheme,
	getConfetti,

	getSystemSettings,

	getStages,
	getFunnels,
	getRefusals,
	getCandidatesSources,
	getDepartments,
	getVacancies,
	getCandidates,
	getApplications,
	getRecruitingLoading,
	getDayStatistic,
	getEmployeeNotifications
}

export default selectors
