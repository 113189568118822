import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'
import * as ReactDOM from 'react-dom/client'
import { Provider } from 'react-redux'
import store from './redux/store/index'

import App from './App'
import './i18n'
//import './firebase'

ReactDOM.createRoot(document.getElementById('root')!).render(
	<Provider store={store}>
		<App />
	</Provider>
)
