import { ShortEmployee } from './employee'
import { PartySurvey } from './survey'

export enum PartyStatusEnum {
	planned = 'planned',
	confirmed = 'confirmed',
	done = 'done'
}

export enum PartyTypeEnum {
	general = 'general',
	local = 'local'
}

export enum PartyMemberStatusEnum {
	waiting = 'waiting',
	agreed = 'agreed',
	disagreed = 'disagreed'
}

export interface PartyMember {
	employee: ShortEmployee
	status: PartyMemberStatusEnum
}

export interface PartyMemberForCreation {
	employee: string
	status: PartyMemberStatusEnum
}

export interface PartyCreationData {
	title: string
	type: PartyTypeEnum
	description: string
	date: Date
	place: string
	members: PartyMemberForCreation[]
}

export interface PartyUpdatingData {
	id: string
	data: PartyCreationData
}

export interface PartyUpdatingData {
	id: string
	data: PartyCreationData
}

export interface UpdateMemberStatusDto {
	partyId: string
	memberId: string
	status: PartyMemberStatusEnum
}

export interface Party {
	_id: string
	date: Date
	place?: string
	type: PartyTypeEnum
	status: PartyStatusEnum
	title: string
	responsible: ShortEmployee
	description: string
	members: PartyMember[]
	survey?: PartySurvey
}
