import { createAction } from '@reduxjs/toolkit'
import Vacation from 'types/vacation'
import VacationLockedPeriods from 'types/vacation.locked.periods'
import * as types from '../../constants'

//vacations
export const loadVacationsRequest = createAction(types.LOAD_VACATIONS_REQUEST)
export const loadVacationsSuccess = createAction<Vacation[]>(types.LOAD_VACATIONS_SUCCESS)
export const loadVacationsError = createAction(types.LOAD_VACATIONS_ERROR)

export const loadEmployeeVacationsRequest = createAction(types.LOAD_EMPLOYEE_VACATIONS_REQUEST)
export const loadEmployeeVacationsSuccess = createAction<Vacation[]>(
	types.LOAD_EMPLOYEE_VACATIONS_SUCCESS
)
export const loadEmployeeVacationsError = createAction(types.LOAD_EMPLOYEE_VACATIONS_ERROR)

export const loadTodayVacationsRequest = createAction(types.LOAD_TODAY_VACATIONS_REQUEST)
export const loadTodayVacationsSuccess = createAction<Vacation[]>(
	types.LOAD_TODAY_VACATIONS_SUCCESS
)
export const loadTodayVacationsError = createAction(types.LOAD_TODAY_VACATIONS_ERROR)

export const createVacationRequest = createAction(types.CREATE_VACATION_REQUEST)
export const createVacationSuccess = createAction<Vacation>(types.CREATE_VACATION_SUCCESS)
export const createVacationError = createAction(types.CREATE_VACATION_ERROR)

export const resolveConflictedVacationsRequest = createAction(
	types.RESOLVE_CONFLICTED_VACATIONS_REQUEST
)
export const resolveConflictedVacationsSuccess = createAction<Vacation[]>(
	types.RESOLVE_CONFLICTED_VACATIONS_SUCCESS
)
export const resolveConflictedVacationsError = createAction(
	types.RESOLVE_CONFLICTED_VACATIONS_ERROR
)

export const updateVacationRequest = createAction(types.UPDATE_VACATION_REQUEST)
export const updateVacationSuccess = createAction<Vacation>(types.UPDATE_VACATION_SUCCESS)
export const updateVacationError = createAction(types.UPDATE_VACATION_ERROR)

export const deleteVacationRequest = createAction(types.DELETE_VACATION_REQUEST)
export const deleteVacationSuccess = createAction<Vacation>(types.DELETE_VACATION_SUCCESS)
export const deleteVacationError = createAction(types.DELETE_VACATION_ERROR)

//Vacation period
export const loadVacationPeriodsRequest = createAction(types.LOAD_VACATION_PERIODS_REQUEST)
export const loadVacationPeriodsSuccess = createAction<VacationLockedPeriods[]>(
	types.LOAD_VACATION_PERIODS_SUCCESS
)
export const loadVacationPeriodsError = createAction(types.LOAD_VACATION_PERIODS_ERROR)

export const createVacationPeriodRequest = createAction(types.CREATE_VACATION_PERIOD_REQUEST)
export const createVacationPeriodSuccess = createAction<VacationLockedPeriods>(
	types.CREATE_VACATION_PERIOD_SUCCESS
)
export const createVacationPeriodError = createAction(types.CREATE_VACATION_PERIOD_ERROR)

export const deleteVacationPeriodRequest = createAction(types.DELETE_VACATION_PERIOD_REQUEST)
export const deleteVacationPeriodSuccess = createAction<VacationLockedPeriods>(
	types.DELETE_VACATION_PERIOD_SUCCESS
)
export const deleteVacationPeriodError = createAction(types.DELETE_VACATION_PERIOD_ERROR)
