import { createAction } from '@reduxjs/toolkit'
import * as types from '../../constants'
import { Subscription } from 'types/subscription'

export const loadSubscriptionsRequest = createAction(types.LOAD_SUBSCRIPTIONS_REQUEST)
export const loadSubscriptionsSuccess = createAction<Subscription[]>(
	types.LOAD_SUBSCRIPTIONS_SUCCESS
)
export const loadSubscriptionsError = createAction(types.LOAD_SUBSCRIPTIONS_ERROR)

export const createSubscriptionRequest = createAction(types.CREATE_SUBSCRIPTION_REQUEST)
export const createSubscriptionSuccess = createAction<Subscription>(
	types.CREATE_SUBSCRIPTION_SUCCESS
)
export const createSubscriptionError = createAction(types.CREATE_SUBSCRIPTION_ERROR)

export const updateSubscriptionRequest = createAction(types.UPDATE_SUBSCRIPTION_REQUEST)
export const updateSubscriptionSuccess = createAction<Subscription>(
	types.UPDATE_SUBSCRIPTION_SUCCESS
)
export const updateSubscriptionError = createAction(types.UPDATE_SUBSCRIPTION_ERROR)

export const archiveSubscriptionRequest = createAction(types.ARCHIVE_SUBSCRIPTION_REQUEST)
export const archiveSubscriptionSuccess = createAction<Subscription>(
	types.ARCHIVE_SUBSCRIPTION_SUCCESS
)
export const archiveSubscriptionError = createAction(types.ARCHIVE_SUBSCRIPTION_ERROR)

export const unarchiveSubscriptionRequest = createAction(types.UNARCHIVE_SUBSCRIPTION_REQUEST)
export const unarchiveSubscriptionSuccess = createAction<Subscription>(
	types.UNARCHIVE_SUBSCRIPTION_SUCCESS
)
export const unarchiveSubscriptionError = createAction(types.UNARCHIVE_SUBSCRIPTION_ERROR)

export const deleteSubscriptionRequest = createAction(types.DELETE_SUBSCRIPTION_REQUEST)
export const deleteSubscriptionSuccess = createAction<Subscription>(
	types.DELETE_SUBSCRIPTION_SUCCESS
)
export const deleteSubscriptionError = createAction(types.DELETE_SUBSCRIPTION_ERROR)
