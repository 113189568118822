import { toastr } from 'react-redux-toastr'
import actions from 'redux/actions'
import {
	AddNewPaymentForEmployee,
	AddSalaryChanges,
	CheckSalaryData,
	RemoveSalaryChanges,
	SalariesTable,
	SalaryTableDollarRateData,
	SalaryTableWithdrawData,
	UpdateSalaryPaymentType,
	UpdateSalaryTableStatusData
} from 'types/salary'
import { t } from 'i18next'
import {
	addBonusesToSalariesTable,
	addFinesToSalariesTable,
	addNewPaymentForEmployee,
	checkEmployeeSalary,
	getSalariesTableByMonth,
	removeBonusFromSalaryTable,
	removeFineFromSalariesTable,
	setSalariesTableDollarRate,
	updateSalaryPaymentType,
	updateSalaryTableStatus,
	withdrawSalariesTableSumms
} from 'utils/api.request'

export const loadSalariesTableByMonthFunc = (month: string) => async (dispatch) => {
	dispatch(actions.dataActions.salariesTable.loadSalariesTableRequest())
	try {
		const salariesTableByMonth: SalariesTable = await getSalariesTableByMonth(month)
		return dispatch(
			actions.dataActions.salariesTable.loadSalariesTableSuccess(salariesTableByMonth)
		)
	} catch (error: any) {
		toastr.error('Error', error.response.data.message)
		return dispatch(actions.dataActions.salariesTable.loadSalariesTableError())
	}
}

export const addNewEmployeePaymentFunc =
	(updatingData: AddNewPaymentForEmployee) => async (dispatch) => {
		dispatch(actions.dataActions.salariesTable.addNewEmployeePaymentRequest())
		try {
			const updatedSalariesTable: SalariesTable = await addNewPaymentForEmployee(updatingData)
			toastr.success('', t('reduxToastrSuccess_newEmployeePaymentAdded'))
			return dispatch(
				actions.dataActions.salariesTable.addNewEmployeePaymentSuccess(updatedSalariesTable)
			)
		} catch (error: any) {
			toastr.error('Error', error.response.data.message)
			return dispatch(actions.dataActions.salariesTable.addNewEmployeePaymentError())
		}
	}

export const addEmployeeBonusesFunc = (updateData: AddSalaryChanges) => async (dispatch) => {
	dispatch(actions.dataActions.salariesTable.addEmployeeBonusesRequest())
	try {
		const updatedSalariesTable: SalariesTable = await addBonusesToSalariesTable(updateData)
		toastr.success('', t('reduxToastrSuccess_bonusesAdded'))
		return dispatch(
			actions.dataActions.salariesTable.addEmployeeBonusesSuccess(updatedSalariesTable)
		)
	} catch (error: any) {
		toastr.error('Error', error.response.data.message)
		return dispatch(actions.dataActions.salariesTable.addEmployeeBonusesError())
	}
}

export const addEmployeeFinesFunc = (updateData: AddSalaryChanges) => async (dispatch) => {
	dispatch(actions.dataActions.salariesTable.addEmployeeBonusesRequest())
	try {
		const updatedSalariesTable: SalariesTable = await addFinesToSalariesTable(updateData)
		toastr.success('', t('reduxToastrSuccess_finesAdded'))
		return dispatch(
			actions.dataActions.salariesTable.addEmployeeBonusesSuccess(updatedSalariesTable)
		)
	} catch (error: any) {
		toastr.error('Error', error.response.data.message)
		return dispatch(actions.dataActions.salariesTable.addEmployeeBonusesError())
	}
}

export const removeEmployeeBonusFunc = (removeData: RemoveSalaryChanges) => async (dispatch) => {
	dispatch(actions.dataActions.salariesTable.removeEmployeeBonusRequest())
	try {
		const updatedSalariesTable: SalariesTable = await removeBonusFromSalaryTable(removeData)
		toastr.success('', t('reduxToastrSuccess_bonusRemoved'))
		return dispatch(
			actions.dataActions.salariesTable.removeEmployeeBonusSuccess(updatedSalariesTable)
		)
	} catch (error: any) {
		toastr.error('Error', error.response.data.message)
		return dispatch(actions.dataActions.salariesTable.removeEmployeeBonusError())
	}
}

export const removeEmployeeFineFunc = (removeData: RemoveSalaryChanges) => async (dispatch) => {
	dispatch(actions.dataActions.salariesTable.removeEmployeeBonusRequest())
	try {
		const updatedSalariesTable: SalariesTable = await removeFineFromSalariesTable(removeData)
		toastr.success('', t('reduxToastrSuccess_fineRemoved'))
		return dispatch(
			actions.dataActions.salariesTable.removeEmployeeBonusSuccess(updatedSalariesTable)
		)
	} catch (error: any) {
		toastr.error('Error', error.response.data.message)
		return dispatch(actions.dataActions.salariesTable.removeEmployeeBonusError())
	}
}

export const updateSalaryPaymentTypeFunc =
	(updatingData: UpdateSalaryPaymentType) => async (dispatch) => {
		dispatch(actions.dataActions.salariesTable.updateSalaryPaymentTypeRequest())
		try {
			const updatedTable: SalariesTable = await updateSalaryPaymentType(updatingData)

			toastr.success('', t(`reduxToastrSuccess_updatePaymentType`))

			return dispatch(
				actions.dataActions.salariesTable.updateSalaryPaymentTypeSuccess(updatedTable)
			)
		} catch (error: any) {
			toastr.error('Error', error.response.data.message)
			return dispatch(actions.dataActions.salariesTable.updateSalaryPaymentTypeError())
		}
	}

export const checkEmployeeSalaryFunc = (checkData: CheckSalaryData) => async (dispatch) => {
	dispatch(actions.dataActions.salariesTable.checkEmployeeSalaryRequest())
	try {
		const updatedSalariesTable: SalariesTable = await checkEmployeeSalary(checkData)
		toastr.success(
			'',
			t(
				checkData.checked
					? 'reduxToastrSuccess_salaryChecked'
					: 'reduxToastrSuccess_salaryUnChecked'
			)
		)
		return dispatch(
			actions.dataActions.salariesTable.checkEmployeeSalarySuccess(updatedSalariesTable)
		)
	} catch (error: any) {
		toastr.error('Error', error.response.data.message)
		return dispatch(actions.dataActions.salariesTable.checkEmployeeSalaryError())
	}
}

export const updateSalariesTableStatusFunc =
	(updatedData: UpdateSalaryTableStatusData) => async (dispatch) => {
		dispatch(actions.dataActions.salariesTable.updateSalariesTableStatusRequest())
		try {
			const updatedTable: SalariesTable = await updateSalaryTableStatus(updatedData)

			toastr.success('', t(`reduxToastrSuccess_${updatedData.status}SalaryTable`))

			return dispatch(
				actions.dataActions.salariesTable.updateSalariesTableStatusSuccess(updatedTable)
			)
		} catch (error: any) {
			toastr.error('Error', error.response.data.message)
			return dispatch(actions.dataActions.salariesTable.updateSalariesTableStatusError())
		}
	}

export const setSalariesTableDollarRateFunc =
	(updatedData: SalaryTableDollarRateData) => async (dispatch) => {
		dispatch(actions.dataActions.salariesTable.setSalariesTableDollarRateRequest())
		try {
			const updatedTable: SalariesTable = await setSalariesTableDollarRate(updatedData)

			toastr.success('', t(`reduxToastrSuccess_dollarRateSalaryTable`))

			return dispatch(
				actions.dataActions.salariesTable.setSalariesTableDollarRateSuccess(updatedTable)
			)
		} catch (error: any) {
			toastr.error('Error', error.response.data.message)
			return dispatch(actions.dataActions.salariesTable.setSalariesTableDollarRateError())
		}
	}

export const withrawSalariesTableSummsFunc =
	(wthdrawData: SalaryTableWithdrawData) => async (dispatch) => {
		dispatch(actions.dataActions.salariesTable.withdrawSalariesTableRequest())
		try {
			const updatedTable: SalariesTable = await withdrawSalariesTableSumms(wthdrawData)

			toastr.success('', t(`reduxToastrSuccess_withdrawSalaryTable`))

			return dispatch(
				actions.dataActions.salariesTable.withdrawSalariesTableSuccess(updatedTable)
			)
		} catch (error: any) {
			toastr.error('Error', error.response.data.message)
			return dispatch(actions.dataActions.salariesTable.withdrawSalariesTableError())
		}
	}
