import React from 'react'
import { useSelector } from 'react-redux'
import { Navigate } from 'react-router-dom'
import selectors from 'redux/selectors'
import { RolesEnum } from 'types/roles'

export default function RolesGuard({ children, routeItem }) {
	const user = useSelector(selectors.getUserEmployee)

	let hasRequiredRole =
		!routeItem.requiredRoles ||
		routeItem.requiredRoles?.some((role) => user.user_roles?.includes(role)) ||
		user.user_roles?.includes(RolesEnum.admin)

	if (
		routeItem.requiredRoles?.includes(RolesEnum.founder) &&
		!user.user_roles.includes(RolesEnum.founder)
	) {
		hasRequiredRole = false
	}

	if (!hasRequiredRole) return <Navigate to="/" />

	return <>{children}</>
}
