import * as types from '../../constants'
import { Application } from 'types/recruiting/application'
import { Candidate } from 'types/recruiting/candidate'
import { CandidatesSource } from 'types/recruiting/candidates.source'
import { Department } from 'types/recruiting/department'
import { Funnel } from 'types/recruiting/funnel'
import { HiringItem } from 'types/recruiting/hiring.item'
import { Refusal } from 'types/recruiting/refusal'
import { Stage } from 'types/recruiting/stage'
import { RecruitingStatistic } from 'types/recruiting/statistic'
import { Vacancy } from 'types/recruiting/vacancy'

export type RecruitingState = {
	loading: boolean
	stages: Stage[]
	funnels: Funnel[]
	refusals: Refusal[]
	candidates_sources: CandidatesSource[]
	candidates: Candidate[]
	vacancies: Vacancy[]
	applications: Application[]
	departments: Department[]
	statistic: RecruitingStatistic
}

const initialState: RecruitingState = {
	loading: false,
	stages: [],
	funnels: [],
	refusals: [],
	candidates_sources: [],
	candidates: [],
	vacancies: [],
	applications: [],
	departments: [],
	statistic: { by_vacancies_and_departments: [] }
}

export default function reducer(state = initialState, actions) {
	switch (actions.type) {
		case types.SET_DEFAULT_STATE:
			return initialState

		case types.LOAD_STAGES_REQUEST:
		case types.CREATE_STAGE_REQUEST:
		case types.UPDATE_STAGES_INDEXES_REQUEST:
		case types.ARCHIVE_STAGE_REQUEST:
		case types.LOAD_FUNNELS_REQUEST:
		case types.CREATE_FUNNEL_REQUEST:
		case types.UPDATE_FUNNEL_REQUEST:
		case types.ARCHIVE_FUNNEL_REQUEST:
		case types.LOAD_CANDIDATES_REQUEST:
		case types.CREATE_CANDIDATE_REQUEST:
		case types.UPDATE_CANDIDATE_REQUEST:
		case types.UPDATE_CANDIDATES_MARKS_REQUEST:
		case types.TAKE_CANDIDATE_ON_VACANCY_REQUEST:
		case types.ADD_NEW_HIRING_HISTORY_ITEM_REQUEST:
		case types.EDIT_HIRING_HISTORY_ITEM_COMMENT_REQUEST:
		case types.UPLOAD_HIRING_HISTORY_ITEM_FILE_REQUEST:
		case types.DELETE_HIRING_HISTORY_ITEM_REQUEST:
		case types.ARCHIVE_CANDIDATE_REQUEST:
		case types.LOAD_VACANCIES_REQUEST:
		case types.CREATE_VACANCY_REQUEST:
		case types.EDIT_VACANCY_REQUEST:
		case types.GET_VACANCY_REQUEST:
		case types.UPDATE_RECRUITERS_REQUEST:
		case types.DELETE_RECRUITER_REQUEST:
		case types.UPDATE_REQUESTERS_REQUEST:
		case types.DELETE_REQUESTER_REQUEST:
		case types.PAUSE_VACANCY_REQUEST:
		case types.CONTINUE_VACANCY_REQUEST:
		case types.CLOSE_VACANCY_REQUEST:
		case types.ARCHIVE_VACANCY_REQUEST:
		case types.LOAD_APPLICATIONS_REQUEST:
		case types.CREATE_APPLICATION_REQUEST:
		case types.EDIT_APPLICATION_REQUEST:
		case types.ACCEPT_APPLICATION_REQUEST:
		case types.REJECT_APPLICATION_REQUEST:
		case types.ARCHIVE_APPLICATION_REQUEST:
		case types.LOAD_DEPARTMENTS_REQUEST:
		case types.CREATE_DEPARTMENT_REQUEST:
		case types.EDIT_DEPARTMENT_REQUEST:
		case types.ARCHIVE_DEPARTMENT_REQUEST:
		case types.LOAD_REFUSALS_REQUEST:
		case types.LOAD_CANDIDATES_SOURCES_REQUEST:
		case types.CREATE_CANDIDATES_SOURCE_REQUEST:
		case types.ARCHIVE_CANDIDATES_SOURCE_REQUEST:
		case types.CREATE_REFUSAL_REQUEST:
		case types.ARCHIVE_REFUSAL_REQUEST:
		case types.LOAD_DAY_STATISTIC_REQUEST:
			return {
				...state,
				loading: true
			}

		//stages
		case types.LOAD_STAGES_SUCCESS:
			return {
				...state,
				loading: false,
				stages: actions.payload
			}

		case types.CREATE_STAGE_SUCCESS:
			return {
				...state,
				loading: false,
				stages: [...state.stages, actions.payload]
			}

		case types.UPDATE_STAGES_INDEXES_SUCCESS:
			return {
				...state,
				loading: false,
				stages: state.stages.map((stage: Stage) => {
					if (stage._id === actions.payload._id) {
						return actions.payload
					}

					return stage
				})
			}

		case types.ARCHIVE_STAGE_SUCCESS:
			return {
				...state,
				loading: false,
				stages: state.stages.filter((stage: Stage) => stage._id !== actions.payload._id)
			}

		//funnels
		case types.LOAD_FUNNELS_SUCCESS:
			return {
				...state,
				loading: false,
				funnels: actions.payload
			}

		case types.CREATE_FUNNEL_SUCCESS:
			return {
				...state,
				loading: false,
				funnels: [...state.funnels, actions.payload]
			}

		case types.UPDATE_FUNNEL_SUCCESS:
			return {
				...state,
				loading: false,
				funnels: state.funnels.map((funnel: Funnel) => {
					if (funnel._id === actions.payload._id) {
						return actions.payload
					}
					return funnel
				})
			}

		case types.ARCHIVE_FUNNEL_SUCCESS:
			return {
				...state,
				loading: false,
				funnels: state.funnels.filter(
					(funnel: Funnel) => funnel._id !== actions.payload._id
				)
			}

		//candidates
		case types.LOAD_CANDIDATES_SUCCESS:
			return {
				...state,
				loading: false,
				candidates: actions.payload
			}

		case types.CREATE_CANDIDATE_SUCCESS:
			return {
				...state,
				loading: false,
				candidates: [actions.payload, ...state.candidates]
			}

		case types.UPDATE_CANDIDATE_SUCCESS:
		case types.TAKE_CANDIDATE_ON_VACANCY_SUCCESS:
			return {
				...state,
				loading: false,
				candidates: state.candidates.map((candidate: Candidate) => {
					if (candidate._id === actions.payload._id) {
						return actions.payload
					}
					return candidate
				})
			}

		case types.UPDATE_CANDIDATES_MARKS_SUCCESS:
			return {
				...state,
				loading: false,
				candidates: state.candidates.map((candidate: Candidate) => {
					if (candidate._id === actions.payload._id) {
						return { ...candidate, marks: actions.payload.marks }
					}
					return candidate
				})
			}

		case types.ADD_NEW_HIRING_HISTORY_ITEM_SUCCESS:
		case types.EDIT_HIRING_HISTORY_ITEM_COMMENT_SUCCESS:
		case types.UPLOAD_HIRING_HISTORY_ITEM_FILE_SUCCESS:
		case types.DELETE_HIRING_HISTORY_ITEM_SUCCESS:
			return {
				...state,
				loading: false,
				candidates: state.candidates.map((candidate: Candidate) => {
					if (candidate._id === actions.payload.candidate) {
						return {
							...candidate,
							hiring_history: candidate.hiring_history.map((item: HiringItem) => {
								if (item._id === actions.payload._id) {
									return actions.payload
								}
								return item
							})
						}
					}
					return candidate
				})
			}

		case types.ARCHIVE_CANDIDATE_SUCCESS:
			return {
				...state,

				loading: false,
				candidates: state.candidates.filter(
					(candidate: Candidate) => candidate._id !== actions.payload._id
				)
			}

		//vacancies
		case types.LOAD_VACANCIES_SUCCESS:
			return {
				...state,
				loading: false,
				vacancies: actions.payload
			}

		case types.GET_VACANCY_SUCCESS:
			return {
				...state,
				loading: false,
				vacancies: [actions.payload]
			}

		case types.CREATE_VACANCY_SUCCESS:
			return {
				...state,

				loading: false,
				vacancies: [actions.payload, ...state.vacancies],
				short_vacancies: [
					...state.vacancies,
					{
						_id: actions.payload._id,
						position: actions.payload.position,
						department: actions.payload.department
					}
				]
			}

		case types.EDIT_VACANCY_SUCCESS:
		case types.UPDATE_RECRUITERS_SUCCESS:
		case types.DELETE_RECRUITER_SUCCESS:
		case types.UPDATE_REQUESTERS_SUCCESS:
		case types.DELETE_REQUESTER_SUCCESS:
		case types.PAUSE_VACANCY_SUCCESS:
		case types.CONTINUE_VACANCY_SUCCESS:
			return {
				...state,
				loading: false,
				vacancies: state.vacancies.map((vacancy: Vacancy) => {
					if (vacancy._id === actions.payload._id) {
						return actions.payload
					}

					return vacancy
				})
			}

		case types.ARCHIVE_VACANCY_SUCCESS:
		case types.CLOSE_VACANCY_SUCCESS:
			return {
				...state,
				loading: false,
				vacancies: state.vacancies.map((vacancy: Vacancy) => {
					if (vacancy._id === actions.payload._id) {
						return actions.payload
					}

					return vacancy
				})
			}

		case types.UPDATE_VACANCY_CANDIDATES_MARKS_SUCCESS:
			return {
				...state,
				loading: false,
				candidates: state.candidates.map((candidate: Candidate) => {
					if (candidate._id === actions.payload._id) {
						return { ...candidate, marks: actions.payload.marks }
					}
					return candidate
				}),
				vacancies: state.vacancies.map((vacancy, i) => {
					if (i === 0) {
						return {
							...vacancy,
							candidates: vacancy.candidates.map((candidate: Candidate) => {
								if (candidate._id === actions.payload._id) {
									return { ...candidate, marks: actions.payload.marks }
								}
								return candidate
							})
						}
					}

					return vacancy
				})
			}

		//applications
		case types.LOAD_APPLICATIONS_SUCCESS:
			return {
				...state,
				loading: false,
				applications: actions.payload
			}

		case types.CREATE_APPLICATION_SUCCESS:
			return {
				...state,
				loading: false,
				applications: [...state.applications, actions.payload]
			}

		case types.ARCHIVE_APPLICATION_SUCCESS:
			return {
				...state,
				loading: false,
				applications: state.applications.filter(
					(application: Application) => application._id !== actions.payload._id
				)
			}

		case types.ACCEPT_APPLICATION_SUCCESS:
			return {
				...state,
				loading: false,
				applications: state.applications.map((application: Application) => {
					if (application._id === actions.payload.application._id) {
						return actions.payload.application
					}

					return application
				}),
				vacancies: state.vacancies.map((vacancy: Vacancy) => {
					if (vacancy._id === actions.payload.vacancy._id) {
						return actions.payload.vacancy
					}

					return vacancy
				})
			}

		case types.EDIT_APPLICATION_SUCCESS:
		case types.REJECT_APPLICATION_SUCCESS:
			return {
				...state,
				loading: false,
				applications: state.applications.map((application: Application) => {
					if (application._id === actions.payload._id) {
						return actions.payload
					}

					return application
				})
			}

		//departments
		case types.LOAD_DEPARTMENTS_SUCCESS:
			return {
				...state,
				loading: false,
				departments: actions.payload
			}

		case types.CREATE_DEPARTMENT_SUCCESS:
			return {
				...state,
				loading: false,
				departments: [...state.departments, actions.payload]
			}

		case types.CREATE_SUBDIVISION_SUCCESS:
			return {
				...state,
				loading: false,
				departments: state.departments.map((department: Department) => {
					if (department._id === actions.payload._id) {
						return actions.payload
					}

					return department
				})
			}

		case types.ARCHIVE_DEPARTMENT_SUCCESS:
			return {
				...state,
				loading: false,
				departments: state.departments.filter(
					(department: Department) => department._id !== actions.payload._id
				)
			}

		case types.ARCHIVE_SUBDIVISION_SUCCESS:
			return {
				...state,
				loading: false,
				departments: state.departments.map((department: Department) => {
					const subdivisionToArchive = department.subdivisions.find(
						(item: Department) => item._id === actions.payload._id
					)

					if (subdivisionToArchive) {
						return {
							...department,
							subdivisions: department.subdivisions.filter(
								(subdivision: Department) =>
									subdivision._id !== subdivisionToArchive._id
							)
						}
					}

					return department
				})
			}

		case types.EDIT_DEPARTMENT_SUCCESS:
			return {
				...state,
				loading: false,
				departments: state.departments.map((department: Department) => {
					if (department._id === actions.payload._id) {
						return actions.payload
					}

					return department
				})
			}

		case types.EDIT_SUBDIVISION_SUCCESS:
			return {
				...state,
				loading: false,
				departments: state.departments.map((department: Department) => {
					if (department._id === actions.payload.parent_department._id) {
						return {
							...department,
							subdivisions: department.subdivisions.map((subdivision: Department) => {
								if (subdivision._id === actions.payload._id) {
									return actions.payload
								}
								return subdivision
							})
						}
					}

					return department
				})
			}

		//refusals
		case types.LOAD_REFUSALS_SUCCESS:
			return {
				...state,
				loading: false,
				refusals: actions.payload
			}

		case types.CREATE_REFUSAL_SUCCESS:
			return {
				...state,
				loading: false,
				refusals: [...state.refusals, actions.payload]
			}

		case types.ARCHIVE_REFUSAL_SUCCESS:
			return {
				...state,
				loading: false,
				refusals: state.refusals.filter(
					(refusal: Refusal) => refusal._id !== actions.payload._id
				)
			}

		//candidates sources
		case types.LOAD_CANDIDATES_SOURCES_SUCCESS:
			return {
				...state,
				loading: false,
				candidates_sources: actions.payload
			}

		case types.CREATE_CANDIDATES_SOURCE_SUCCESS:
			return {
				...state,
				loading: false,
				candidates_sources: [...state.candidates_sources, actions.payload]
			}

		case types.ARCHIVE_CANDIDATES_SOURCE_SUCCESS:
			return {
				...state,
				loading: false,
				candidates_sources: state.candidates_sources.filter(
					(source: CandidatesSource) => source._id !== actions.payload._id
				)
			}

		//statistic
		case types.LOAD_DAY_STATISTIC_SUCCESS:
			return {
				...state,
				loading: false,
				statistic: { ...state.statistic, by_vacancies_and_departments: actions.payload }
			}

		case types.LOAD_FUNNELS_ERROR:
		case types.CREATE_FUNNEL_ERROR:
		case types.ARCHIVE_FUNNEL_ERROR:
		case types.LOAD_STAGES_ERROR:
		case types.CREATE_STAGE_ERROR:
		case types.UPDATE_STAGES_INDEXES_ERROR:
		case types.ARCHIVE_STAGE_ERROR:
		case types.LOAD_CANDIDATES_ERROR:
		case types.CREATE_CANDIDATE_ERROR:
		case types.UPDATE_CANDIDATE_ERROR:
		case types.UPDATE_CANDIDATES_MARKS_ERROR:
		case types.TAKE_CANDIDATE_ON_VACANCY_ERROR:
		case types.ARCHIVE_CANDIDATE_ERROR:
		case types.LOAD_VACANCIES_ERROR:
		case types.GET_VACANCY_ERROR:
		case types.CREATE_VACANCY_ERROR:
		case types.EDIT_VACANCY_ERROR:
		case types.UPDATE_RECRUITERS_ERROR:
		case types.DELETE_RECRUITER_ERROR:
		case types.UPDATE_REQUESTERS_ERROR:
		case types.DELETE_REQUESTER_ERROR:
		case types.PAUSE_VACANCY_ERROR:
		case types.CONTINUE_VACANCY_ERROR:
		case types.CLOSE_VACANCY_ERROR:
		case types.ARCHIVE_VACANCY_ERROR:
		case types.ADD_NEW_HIRING_HISTORY_ITEM_ERROR:
		case types.EDIT_HIRING_HISTORY_ITEM_COMMENT_ERROR:
		case types.UPLOAD_HIRING_HISTORY_ITEM_FILE_ERROR:
		case types.DELETE_HIRING_HISTORY_ITEM_ERROR:
		case types.LOAD_APPLICATIONS_ERROR:
		case types.CREATE_APPLICATION_ERROR:
		case types.EDIT_APPLICATION_ERROR:
		case types.ARCHIVE_APPLICATION_ERROR:
		case types.LOAD_DEPARTMENTS_ERROR:
		case types.CREATE_DEPARTMENT_ERROR:
		case types.EDIT_DEPARTMENT_ERROR:
		case types.ARCHIVE_DEPARTMENT_ERROR:
		case types.LOAD_REFUSALS_ERROR:
		case types.CREATE_REFUSAL_ERROR:
		case types.ARCHIVE_REFUSAL_ERROR:
		case types.LOAD_CANDIDATES_SOURCES_ERROR:
		case types.CREATE_CANDIDATES_SOURCE_ERROR:
		case types.ARCHIVE_CANDIDATES_SOURCE_ERROR:
		case types.LOAD_DAY_STATISTIC_ERROR:
			return {
				...state,
				loading: false
			}

		default:
			return state
	}
}
