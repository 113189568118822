import * as types from '../constants'
import { createAction } from '@reduxjs/toolkit'
import Employee from 'types/employee'
import WorkCheckin from 'types/workCheckin'
import { NoSurvey, SurveyState } from 'types/survey'
import { FetchUserStatus, LoginUserStatus } from 'types/user'

export const loginRequest = createAction(types.LOGIN_REQUEST)
export const loginSuccess = createAction<LoginUserStatus>(types.LOGIN_SUCCESS)
export const loginError = createAction(types.LOGIN_ERROR)

export const logoutRequest = createAction(types.LOGOUT_REQUEST)
export const logoutSuccess = createAction(types.LOGOUT_SUCCESS)
export const logoutError = createAction(types.LOGOUT_ERROR)

export const fetchUserRequest = createAction(types.FETCH_USER_REQUEST)
export const fetchCurrentUserTokenSuccess = createAction<Employee>(types.FETCH_USER_TOKEN_SUCCESS)
export const fetchUserSuccess = createAction<FetchUserStatus>(types.FETCH_USER_SUCCESS)
export const fetchUserError = createAction(types.FETCH_USER_ERROR)

export const addWorkCheckinRequest = createAction(types.ADD_WORK_CHECKIN_REQUEST)
export const addWorkCheckinSuccess = createAction<WorkCheckin>(types.ADD_WORK_CHECKIN_SUCCESS)
export const addWorkCheckinError = createAction(types.ADD_WORK_CHECKIN_ERROR)

export const addEndDayWorkCheckinRequest = createAction(types.ADD_END_DAY_WORK_CHECKIN_REQUEST)
export const addEndDayWorkCheckinSuccess = createAction<WorkCheckin>(
	types.ADD_END_DAY_WORK_CHECKIN_SUCCESS
)
export const addEndDayWorkCheckinError = createAction(types.ADD_END_DAY_WORK_CHECKIN_ERROR)

export const loadSurveyRequest = createAction(types.LOAD_SURVEY_REQUEST)
export const loadSurveySuccess = createAction<SurveyState | NoSurvey>(types.LOAD_SURVEY_SUCCESS)
export const loadSurveyError = createAction(types.LOAD_SURVEY_ERROR)

export const sendAnswerRequest = createAction(types.SEND_ANSWER_REQUEST)
export const sendAnswerSuccess = createAction(types.SEND_ANSWER_SUCCESS)
export const sendAnswerError = createAction(types.SEND_ANSWER_ERROR)

export const saveEmailRequest = createAction(types.SAVE_EMAIL_REQUEST)
export const saveEmailSuccess = createAction<Employee>(types.SAVE_EMAIL_SUCCESS)
export const saveEmailError = createAction(types.SAVE_EMAIL_ERROR)

export const workWithSystem = createAction(types.WORK_WITH_SYSTEM)
export const clearUser = createAction(types.CLEAR_USER)

export const disableEvents = createAction(types.EVENTS_DISABLE)
export const enableEvets = createAction(types.EVENTS_ENABLE)
