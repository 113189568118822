import { toastr } from 'react-redux-toastr'
import actions from 'redux/actions'
import { t } from 'i18next'
import {
	acceptApplication,
	archiveApplication,
	createApplication,
	editApplication,
	loadApplications,
	rejectApplication
} from 'utils/api.request'
import {
	Application,
	ApplicationCreationData,
	ApplicationEditData,
	ApplicationRejectingData
} from 'types/recruiting/application'
import { AcceptApplicationData, ApplicationVacancyRequest } from 'types/recruiting/vacancy'

export const loadApplicationsFunc = () => async (dispatch) => {
	dispatch(actions.dataActions.applications.loadApplicationsRequest())
	try {
		const allApplications: Application[] = await loadApplications()

		return dispatch(actions.dataActions.applications.loadApplicationsSuccess(allApplications))
	} catch (error: any) {
		toastr.error('Error', error.response.data.message)
		return dispatch(actions.dataActions.applications.loadApplicationsError())
	}
}

export const createApplicationFunc =
	(creationData: ApplicationCreationData) => async (dispatch) => {
		dispatch(actions.dataActions.applications.createApplicationRequest())
		try {
			const newApplication: Application = await createApplication(creationData)

			toastr.success('', t('reduxToastrSuccess_createApplication'))
			return dispatch(
				actions.dataActions.applications.createApplicationSuccess(newApplication)
			)
		} catch (error: any) {
			toastr.error('Error', error.response.data.message)
			return dispatch(actions.dataActions.applications.createApplicationError())
		}
	}

export const editApplicationFunc = (editData: ApplicationEditData) => async (dispatch) => {
	dispatch(actions.dataActions.applications.editApplicationRequest())
	try {
		const updatedApplication: Application = await editApplication(editData)

		toastr.success('', t('reduxToastrSuccess_editApplication'))
		return dispatch(actions.dataActions.applications.editApplicationSuccess(updatedApplication))
	} catch (error: any) {
		toastr.error('Error', error.response.data.message)
		return dispatch(actions.dataActions.applications.editApplicationError())
	}
}

export const acceptApplicationFunc = (acceptData: AcceptApplicationData) => async (dispatch) => {
	dispatch(actions.dataActions.applications.acceptApplicationRequest())
	try {
		const data: ApplicationVacancyRequest = await acceptApplication(acceptData)

		toastr.success('', t('reduxToastrSuccess_acceptApplication'))
		return dispatch(actions.dataActions.applications.acceptApplicationSuccess(data))
	} catch (error: any) {
		toastr.error('Error', error.response.data.message)
		return dispatch(actions.dataActions.applications.acceptApplicationError())
	}
}

export const rejectApplicationFunc =
	(rejectingData: ApplicationRejectingData) => async (dispatch) => {
		dispatch(actions.dataActions.applications.rejectApplicationRequest())
		try {
			const deletedApplication: Application = await rejectApplication(rejectingData)

			toastr.success('', t('reduxToastrSuccess_rejectApplication'))
			return dispatch(
				actions.dataActions.applications.rejectApplicationSuccess(deletedApplication)
			)
		} catch (error: any) {
			toastr.error('Error', error.response.data.message)
			return dispatch(actions.dataActions.applications.rejectApplicationError())
		}
	}

export const archiveApplicationFunc = (application_id: string) => async (dispatch) => {
	dispatch(actions.dataActions.applications.archiveApplicationRequest())
	try {
		const deletedApplication: Application = await archiveApplication(application_id)

		toastr.success('', t('reduxToastrSuccess_archiveApplication'))
		return dispatch(
			actions.dataActions.applications.archiveApplicationSuccess(deletedApplication)
		)
	} catch (error: any) {
		toastr.error('Error', error.response.data.message)
		return dispatch(actions.dataActions.applications.archiveApplicationError())
	}
}
