import { toastr } from 'react-redux-toastr'
import actions from 'redux/actions'
import { t } from 'i18next'
import {
	archiveCandidatesSource,
	createCandidatesSource,
	loadCandidatesSources
} from 'utils/api.request'
import { CandidatesSource, CandidatesSourceCreationData } from 'types/recruiting/candidates.source'

export const loadCandidatesSourcesFunc = () => async (dispatch) => {
	dispatch(actions.dataActions.candidates_sources.loadCandidatesSourcesRequest())
	try {
		const candidatesSources: CandidatesSource[] = await loadCandidatesSources()

		return dispatch(
			actions.dataActions.candidates_sources.loadCandidatesSourcesSuccess(candidatesSources)
		)
	} catch (error: any) {
		toastr.error('Error', error.response.data.message)
		return dispatch(actions.dataActions.candidates_sources.loadCandidatesSourcesError())
	}
}

export const createCandidatesSourceFunc =
	(creationData: CandidatesSourceCreationData) => async (dispatch) => {
		dispatch(actions.dataActions.candidates_sources.createCandidatesSourceRequest())
		try {
			const candidatesSources: CandidatesSource = await createCandidatesSource(creationData)

			toastr.success('', t('reduxToastrSuccess_createCandidatesSource'))
			return dispatch(
				actions.dataActions.candidates_sources.createCandidatesSourceSuccess(
					candidatesSources
				)
			)
		} catch (error: any) {
			console.log(error)
			toastr.error('Error', error.response.data.message[0])
			return dispatch(actions.dataActions.candidates_sources.createCandidatesSourceError())
		}
	}

export const archiveCandidatesSourceFunc = (stage_id: string) => async (dispatch) => {
	dispatch(actions.dataActions.candidates_sources.archiveCandidatesSourceRequest())
	try {
		const deletedCandidatesSource: CandidatesSource = await archiveCandidatesSource(stage_id)

		toastr.success('', t('reduxToastrSuccess_deleteCandidatesSource'))
		return dispatch(
			actions.dataActions.candidates_sources.archiveCandidatesSourceSuccess(
				deletedCandidatesSource
			)
		)
	} catch (error: any) {
		toastr.error('Error', error.response.data.message)
		return dispatch(actions.dataActions.candidates_sources.archiveCandidatesSourceError())
	}
}
