import { toastr } from 'react-redux-toastr'
import actions from 'redux/actions'
import { t } from 'i18next'
import { archiveRefusal, createRefusal, loadRefusals } from 'utils/api.request'
import { Refusal, RefusalCreationData } from 'types/recruiting/refusal'

export const loadRefusalsFunc = () => async (dispatch) => {
	dispatch(actions.dataActions.refusals.loadRefusalsRequest())
	try {
		const allRefusals: Refusal[] = await loadRefusals()

		return dispatch(actions.dataActions.refusals.loadRefusalsSuccess(allRefusals))
	} catch (error: any) {
		toastr.error('Error', error.response.data.message)
		return dispatch(actions.dataActions.refusals.loadRefusalsError())
	}
}

export const createRefusalFunc = (creationData: RefusalCreationData) => async (dispatch) => {
	dispatch(actions.dataActions.refusals.createRefusalRequest())
	try {
		const newRefusal: Refusal = await createRefusal(creationData)

		toastr.success('', t('reduxToastrSuccess_createRefusal'))
		return dispatch(actions.dataActions.refusals.createRefusalSuccess(newRefusal))
	} catch (error: any) {
		console.log(error)
		toastr.error('Error', error.response.data.message[0])
		return dispatch(actions.dataActions.refusals.createRefusalError())
	}
}

export const archiveRefusalFunc = (refusal_id: string) => async (dispatch) => {
	dispatch(actions.dataActions.refusals.archiveRefusalRequest())
	try {
		const deletedRefusal: Refusal = await archiveRefusal(refusal_id)

		toastr.success('', t('reduxToastrSuccess_deleteRefusal'))
		return dispatch(actions.dataActions.refusals.archiveRefusalSuccess(deletedRefusal))
	} catch (error: any) {
		toastr.error('Error', error.response.data.message)
		return dispatch(actions.dataActions.refusals.archiveRefusalError())
	}
}
