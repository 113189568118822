import { createAction } from '@reduxjs/toolkit'
import * as types from '../../../constants'
import { CandidatesSource } from 'types/recruiting/candidates.source'

export const loadCandidatesSourcesRequest = createAction(types.LOAD_CANDIDATES_SOURCES_REQUEST)
export const loadCandidatesSourcesSuccess = createAction<CandidatesSource[]>(
	types.LOAD_CANDIDATES_SOURCES_SUCCESS
)
export const loadCandidatesSourcesError = createAction(types.LOAD_CANDIDATES_SOURCES_ERROR)

export const createCandidatesSourceRequest = createAction(types.CREATE_CANDIDATES_SOURCE_REQUEST)
export const createCandidatesSourceSuccess = createAction<CandidatesSource>(
	types.CREATE_CANDIDATES_SOURCE_SUCCESS
)
export const createCandidatesSourceError = createAction(types.CREATE_CANDIDATES_SOURCE_ERROR)

export const archiveCandidatesSourceRequest = createAction(types.ARCHIVE_CANDIDATES_SOURCE_REQUEST)
export const archiveCandidatesSourceSuccess = createAction<CandidatesSource>(
	types.ARCHIVE_CANDIDATES_SOURCE_SUCCESS
)
export const archiveCandidatesSourceError = createAction(types.ARCHIVE_CANDIDATES_SOURCE_ERROR)
