export enum RolesEnum {
	base = 'base',
	qa = 'qa',
	hr = 'hr',
	management = 'management',
	mentor = 'mentor',
	art_mentor = 'art_mentor',
	tech_lead = 'tech_lead',
	art_lead = 'art_lead',
	co_lead = 'co_lead',
	lead_level_designer = 'lead_level_designer',
	tech_setup = 'tech_setup',
	project_manager = 'project_manager',
	producer_asistant = 'producer_asistant',
	assistant_manager = 'assistant_manager',
	founder = 'founder',
	admin = 'admin',

	// Menu
	gantt_chart = 'gantt_chart',
	ideas = 'ideas',
	ratings = 'ratings',
	statistics = 'statistics',
	developers = 'developers',
	employees = 'employees',
	recruiting = 'recruiting',
	publishers = 'publishers',
	survey = 'survey',
	invoices = 'invoices',
	finances = 'finances',
	subscriptions = 'subscriptions',
	balances = 'balances',
	expenses = 'expenses',
	salary = 'salary'
}
